@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
  th, td {
            padding: 8px;
            text-align: left;
        }
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.phone-input-custom .PhoneInputInput {
  height: 50px; 
  padding-left: 10px; 
  border-radius: 7px;
  outline: none;
  box-shadow: none; 
}

/* variables.css */
:root {
  --main-color: #243784;
  --secondary-color: #ffed4a;
  --logo-main-color: #243784;
  --logo-secondary-color: #fff643;
}
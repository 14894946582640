.navbarr {
  position: relative;
}

.navbarr .animation {
  position: absolute;
  height: 2px;
  bottom: 0; 
  z-index: 0;
  background: var(--main-color);
  width: 0%;
  border-radius: 8px;
  transition: width 0.3s ease, left 0.3s ease;
}

/* Default to full width when no link is active */
.navbarr:not(.active-link) .animation {
  width: 100%;
  left: 0;
}

.navbarr .linkk {
  width: 120px;
}


.navbarr .linkk:nth-child(1).active ~ .animation {
  width: 90px;
  margin: 0 15px;
}
.navbarr .linkk:nth-child(2).active ~ .animation {
  width: 80px;
  margin: 0 20px;
}
.navbarr .linkk:nth-child(3).active ~ .animation {
  width: 100px;
  margin: 0 10px;

}
.navbarr .linkk:nth-child(4).active ~ .animation {
  width: 80px;
  margin: 0 20px;
}
.navbarr .linkk:nth-child(5).active ~ .animation {
  width: 60px;
  margin: 0 30px;
}

/*  */

.navbarr .linkk:nth-child(1).active ~ .animation {
  left: 0;
}

.navbarr .linkk:nth-child(2).active ~ .animation {
  left: 120px;
}

.navbarr .linkk:nth-child(3).active ~ .animation {
  left: 240px;
}

.navbarr .linkk:nth-child(4).active ~ .animation {
  left: 360px;
}

.navbarr .linkk:nth-child(5).active ~ .animation {
  left: 480px;
}

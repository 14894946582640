.time-container {
    position: relative;
}

.time-container .animation {
  position: absolute;
  height: 2px;
  bottom: 0; 
  z-index: 0;
  background: var(--main-color);
  width: 0%;
  border-radius: 8px;
  transition: width 0.3s ease, left 0.3s ease;
}


/* Default to full width when no link is active */
.time-container .date-option {
  width: 80px;
}

.time-container .date-option:nth-child(1).active-date-option ~ .animation {
  width: 40px;
  margin: 0 20px;
}

.time-container .date-option:nth-child(2).active-date-option ~ .animation {
  width: 45px;
  margin: 0 18px;
}

.time-container .date-option:nth-child(3).active-date-option ~ .animation {
  width: 50px;
  margin: 0 15px;
}

.time-container .date-option:nth-child(4).active-date-option ~ .animation {
  width: 40px;
  margin: 0 20px;
}


/*  */
.time-container .date-option:nth-child(1).active-date-option ~ .animation {
  left: 0;
}

.time-container .date-option:nth-child(2).active-date-option ~ .animation {
  left: 80px;
}

.time-container .date-option:nth-child(3).active-date-option ~ .animation {
  left: 160px;
}

.time-container .date-option:nth-child(4).active-date-option ~ .animation {
  left: 240px;
}
.time-container .date-option:nth-child(5).active-date-option ~ .animation {
  width: 80px; /* Adjust as needed */
  margin: 0; /* Adjust as needed */
}

.time-container .date-option:nth-child(5).active-date-option ~ .animation {
  left: 320px; /* Adjust based on the width of the previous tabs and their margins */
}
